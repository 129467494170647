var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "title": "CodeDrills Career",
      "apiStatusList": []
    }
  }, [_vm._v(" Coming Soon ... ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }